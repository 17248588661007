export const BASE_URL = process.env.GATSBY_BASE_URL
export const QUALIFICATION_URL = process.env.GATSBY_QUALIFICATION_URL
export const GATSBY_MAPBOX_API_KEY = process.env.GATSBY_MAPBOX_API_KEY
export const DEBUG = process.env.GATSBY_DEBUG === 'true'
export const PROJECT_REF = process.env.GATSBY_PROJECT_REF
export const GATSBY_IP_LOCATION = process.env.GATSBY_IP_LOCATION
export const UTM_KEY = process.env.GATSBY_UTM_KEY
export const PROJECT_DISPLAY_TITLE = process.env.GATSBY_PROJECT_DISPLAY_TITLE
export const defaultLanguage = process.env.GATSBY_DEFAULT_LANGUAGE.toString()
export const COUNTRY = process.env.GATSBY_COUNTRY.toString()
export const ZIPCODE_MIN = process.env.GATSBY_ZIPCODE_MIN ? parseInt(process.env.GATSBY_ZIPCODE_MIN) : null
export const ZIPCODE_MAX = process.env.GATSBY_ZIPCODE_MAX ? parseInt(process.env.GATSBY_ZIPCODE_MAX) : null

const GATSBY_LANGUAGES_OBJ =
  process.env.GATSBY_LANGUAGES_OBJ ||
  JSON.stringify({ [defaultLanguage]: { path: '/', nativeName: defaultLanguage, country: COUNTRY } }, null, 4)

export const LANGUAGES_LOOKUP = JSON.parse(GATSBY_LANGUAGES_OBJ)

const debugConfig = {
  zipcode_max: ZIPCODE_MAX,
  zipcode_min: ZIPCODE_MIN,
  defaultLanguage: defaultLanguage,
  country: COUNTRY,
  project_ref: PROJECT_REF,
  gatsby_languages_obj: GATSBY_LANGUAGES_OBJ,
  languages_lookup: LANGUAGES_LOOKUP,
  project_display_title: PROJECT_DISPLAY_TITLE,
}

console.log(' constant > debugConfig = ', debugConfig)

export const PRIMARY_COLOR = '#121533'

export const CONTACT = {
  email: 'connect@studiesandme.com',
  address: 'Hauser Pl. 20, 1',
  zip: 'DK-1127',
  city: 'Copenhagen K',
  country: 'Denmark',
}
